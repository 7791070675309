import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template(data) {
  console.log(data)
  const { frontmatter, html } = data.pageContext.node

  return (
    <Layout cssClass="card">
      <SEO title={frontmatter.title}></SEO>
      <h2 className="marker"> {frontmatter.title} </h2>
      {new Date(frontmatter.date).toDateString()}
      <div
        className="blog-post-content"
        dangerouslySetInnerHTML={{ __html: html }}
      ></div>
    </Layout>
  )
}
